import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { login_details, clearLoginData } from "../redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from 'next/dist/client/router';
import { absoluteUrl } from '../components/getAbsoluteUrl';
import HeadContent from '../components/HeadContent';
import FacebookLogin from 'react-facebook-login';
import { loginType, socialLoginClientId } from "../components/global";
import { signIn, useSession } from 'next-auth/client'

export default function login() {
    const [session, loading] = useSession()
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [btn, setbtn] = useState('SIGN IN');
    const [login, setlogin] = useState({
        email: "",
        password: ""
    });

    var params = new URLSearchParams(window.location.search);

    const registerSuccess = params.get("register");
    useEffect(() => {
        if (registerSuccess == 'success') {

            toast.success('Thanks for joining ! Please check your email for verification link.', { position: "top-center" })

        }
    }, [])
    const router = useRouter();
    const dispatch = useDispatch();
    if (session && session.user) {
        let firstName = session.user.name.split(' ').slice(0, -1).join(' ');
        let lastName = session.user.name.split(' ').slice(-1).join(' ');
        dispatch(login_details({ email: session.user.email, firstName: firstName, lastName: lastName, profileImage: session.user.image, loginType: loginType.google }));
    }

    var loadScript = function (src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName("div")[0].appendChild(tag);
    }

    useEffect(() => {
        async function fetchMyAPI() {
            try {

                const id = params.get("id");
                const vc = params.get("vc");
                if (id != null && id.trim() != null && vc != null && vc.trim() != null) {

                    var ltd = setInterval(function () {
                        loadScript('https://cdn.jsdelivr.net/npm/pace-js@latest/pace.min.js');
                    }, 1000);
                    const res = await fetch(`/api/auth/verification`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            'uid': id,
                            'uvc': vc
                        })
                    });

                    if (res.status >= 200 && res.status < 300) {
                        clearInterval(ltd);
                        const data = await res.json();
                        // check later on wether to keep cookie or not;
                        document.cookie = `customer=${data.customerId}; expires=Thu, 18 Dec 2023 12:00:00 UTC; path=/`;
                        router.replace("/")
                        toast.success('Your Email has been verfied successfully', { position: "top-center" })

                    } else {
                        toast.error('Something went wrong', { position: "top-center" })
                    }
                } else {
                    return {
                        props: {}
                    }
                }
            } catch (error) {
                alert("ERROR: ", error);
            }
        }
        fetchMyAPI()
    }, [])

    const logindata = useSelector((state) => state.user);
    var islogged = logindata.islogged;

    if (islogged) {
        router.replace('/');
    }
    //new code
    let retrievedArray = localStorage.getItem('userArray');
    let userLoginArray = null;
    if (retrievedArray) {
        userLoginArray = JSON.parse(retrievedArray)
    }
    let renderRememberLogin = null
    if (userLoginArray) {
        renderRememberLogin = userLoginArray.map((user, i) => {
            let imagesrc = user.profileImage ?? 'images/person.png';
            return (
                <div key={i}>
                    <div className="remember-login-user" onClick={() => displayEmail({ email: user.email })}>
                        <div className="remember-login-user-div1">
                            <img className="rounded-circle img-profile-login" id="remember-img-profile-login" width="10" src={imagesrc} alt="" />
                            <h5>{user.firstName} {user.lastName ?? ''}</h5>

                        </div>
                        <div className="remember-login-user-div1" id='remember-login-user-div1' onClick={() => deleteRememberme(i)}>
                            <p><i className="fa fa-close"></i></p>
                        </div>
                    </div>
                </div>
            )
        })
    }
    const deleteRememberme = (i) => {
        userLoginArray.splice(i, 1);
        localStorage.setItem('userArray', JSON.stringify(userLoginArray));
    }


    useEffect(() => {
        var errorLogin = logindata.errorLogin;
        if (errorLogin) {

            if (errorLogin == 'Invalid Email') {
                const Msg = () => (
                    <>
                        <p>Sorry, we couldn't find an account with that username. Do you want to <a href="/join" className="toastify-msg"><u>SignUp</u></a> ?</p>
                    </>
                )
                toast.error(<Msg />, {
                    position: "top-center",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (errorLogin == 'Please verify your email') {

                const Msg = () => (
                    <>
                        <p> Please verify your email </p>
                    </>
                )
                toast.error(<Msg />, {
                    position: "top-center",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else {
                const Msg = () => (
                    <>
                        <p>Entered Password is wrong. Click on <a href="/forgotPassword" className="toastify-msg"><u>Forgot Password</u></a>?</p>
                    </>
                )
                toast.error(<Msg />, {
                    position: "top-center",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearLoginData());
            setbtn('SIGN IN');
        }
    });
    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setlogin({ ...login, [name]: value })
    }

    const responseFacebook = (response) => {
        if (response.status != "unknown") {
            var firstName = response.name.split(' ').slice(0, -1).join(' ');
            var lastName = response.name.split(' ').slice(-1).join(' ');
            setbtn('Wait...');
            try {
                dispatch(login_details({ firstName: firstName, lastName: lastName, email: response.email, socialLoginId: response.id, profileImage: response?.picture?.data?.url, loginType: loginType.facebook }));
            } catch (error) {
                setbtn('SIGN IN');
                toast.error("Something wrong! Please try again later", { position: "top-center" });
            }
        }
    }

    const responseGoogle = (response) => {
        if (response.googleId) {
            setbtn('Wait...');
            try {
                dispatch(login_details({ email: response.profileObj.email, firstName: response.profileObj.givenName, lastName: response.profileObj.familyName, socialLoginId: response.googleId, profileImage: response.profileObj.imageUrl, loginType: loginType.google }));
            } catch (error) {
                setbtn('SIGN IN');
                toast.error("Something wrong! Please try again later ", { position: "top-center" });
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setbtn('Wait...');
        try {
            dispatch(login_details({ email: login.email.toLowerCase().trim(), password: login.password.trim(), loginType: loginType.email }));
        } catch (error) {
            setbtn('SIGN IN');
            toast.error("Something wrong! Please try again later", { position: "top-center" });
        }
    }

    const displayEmail = ({ email }) => {
        setlogin({ ...login, email: email })
    }

    const togglePassword = () => {
        if (isPasswordShown) {
            setIsPasswordShown(false)
        } else {
            setIsPasswordShown(true)
        }
    }


    return (
        <>
            <HeadContent title={'Login'} isBusyLoader={true} isMeta={true} />
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="container">
                        <div className="login-wrap">
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
                                <img alt="yam" src="images/logo.png" style={{ width: "80%", height: "150px" }} />
                            </div>
                            <div className="login-content">
                                <div className="login-logo">
                                    <h2>Login to Yam</h2>
                                </div>
                                {renderRememberLogin}
                                {/* {retrievedObject ?
                                    <div className="remember-login-user" onClick={displayEmail}>
                                        <img className="rounded-circle img-profile-login" id="remember-img-profile-login" width="10" src={imagesrc} alt="" />
                                        <h5>{userLoginObj.firstName} {userLoginObj.lastName ?? ''}</h5>
                                    </div> : null} */}
                                <div className="login-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input className="au-input au-input--full" type="email" name="email" placeholder="Email" value={login.email} onChange={handleInput} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input className="au-input au-input--full" type={isPasswordShown ? "text" : "password"} name="password" placeholder="Password" value={login.password} onChange={handleInput} required />
                                            <i className="fa fa-eye password-icon" onClick={togglePassword} />
                                        </div>
                                        <button type="submit" className="au-btn au-btn--block au-btn--green m-b-20 text-center">{btn}</button>
                                        <FacebookLogin
                                            appId={socialLoginClientId.facebook_client_id}
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            callback={responseFacebook}
                                            cssClass="au-btn au-btn--block au-btn--facebook m-b-20 text-center"
                                            icon="fa-facebook "
                                            textButton=" Sign In with Facebook"
                                        >
                                        </FacebookLogin>
                                        <a className="au-btn--block m-b-20 text-center" id="googlebtn" onClick={(e) => { signIn("google"); }}><i className="fab fa-google"></i> &nbsp;SIGN IN WITH GOOGLE</a>
                                        <br />
                                        <Link href="/join">
                                            <a className="m-b-20">New user? Sign up</a>
                                        </Link>
                                        <div className="login-checkbox">
                                            <label>
                                                <Link href="/forgotPassword">
                                                    <a>Forgot Password?</a>
                                                </Link>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={false} />
        </>
    );
}